/**
 * Mixin for triggering search event which is in header, to component which is currently mounted
 * 
 * !!! In Component, there has to be method searchFromHeader, which accepts keyword argument and calls data search function !!!
 */

export default {
    mounted() {
        this.emitter.emit("show-header-search");
        this.emitter.on("search-from-header", this.searchFromHeader);
    },
    unmounted() {
        this.emitter.emit("hide-header-search");
        this.emitter.off("search-from-header", this.searchFromHeader);
    }
}