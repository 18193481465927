<template>
    <div class="table-view industries-table-view">
        <div class="action-header">
            <h1>{{ $t('Industries') }}</h1>
            <div class="actions">
                <button type="button" class="btn btn-grey" @click="showAddComponent=true">
                    {{ $t('Add') }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="18.285" height="18.285" viewBox="0 0 18.285 18.285">
                        <g>
                            <g>
                                <path d="M8.148 2.5H4.87A2.37 2.37 0 0 0 2.5 4.87v3.278a2.37 2.37 0 0 0 2.37 2.37h3.278a2.37 2.37 0 0 0 2.37-2.37V4.87a2.37 2.37 0 0 0-2.37-2.37zm.339 5.648a.339.339 0 0 1-.339.339H4.87a.339.339 0 0 1-.339-.339V4.87a.339.339 0 0 1 .339-.339h3.278a.339.339 0 0 1 .339.339zM18.415 2.5h-3.278a2.37 2.37 0 0 0-2.37 2.37v3.278a2.37 2.37 0 0 0 2.37 2.37h3.278a2.37 2.37 0 0 0 2.37-2.37V4.87a2.37 2.37 0 0 0-2.37-2.37zm.339 5.648a.339.339 0 0 1-.339.339h-3.278a.339.339 0 0 1-.339-.339V4.87a.339.339 0 0 1 .339-.339h3.278a.339.339 0 0 1 .339.339zM8.148 12.767H4.87a2.37 2.37 0 0 0-2.37 2.37v3.278a2.37 2.37 0 0 0 2.37 2.37h3.278a2.37 2.37 0 0 0 2.37-2.37v-3.278a2.37 2.37 0 0 0-2.37-2.37zm.339 5.648a.339.339 0 0 1-.339.339H4.87a.339.339 0 0 1-.339-.339v-3.278a.339.339 0 0 1 .339-.337h3.278a.339.339 0 0 1 .339.339zm12.3-1.639a1.016 1.016 0 0 1-1.016 1.016h-1.979v1.978a1.016 1.016 0 1 1-2.032 0v-1.978h-1.977a1.016 1.016 0 1 1 0-2.032h1.977v-1.977a1.016 1.016 0 1 1 2.032 0v1.977h1.978a1.016 1.016 0 0 1 1.015 1.016z" transform="translate(-2.5 -2.5) translate(2.5 2.5) translate(-2.5 -2.5)"/>
                            </g>
                        </g>
                    </svg>
                </button>
                <button type="button" class="btn btn-grey" @click="getIndustries">
                    {{ $t('Refresh') }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.948" height="20" viewBox="0 0 20.948 20">
                        <g>
                            <g>
                                <path d="M10 13a8.37 8.37 0 0 1 7.528 4.47 3 3 0 0 1-2.58 4.53h-9.9a3 3 0 0 1-2.581-4.53A8.37 8.37 0 0 1 10 13zm0 2a6.383 6.383 0 0 0-5.808 3.49 1 1 0 0 0 .86 1.51h9.9a1 1 0 0 0 .86-1.51A6.383 6.383 0 0 0 10 15zm9-7a1 1 0 0 1 .993.883L20 9v2h2a1 1 0 0 1 .117 1.993L22 13h-2v2a1 1 0 0 1-1.993.117L18 15v-2h-2a1 1 0 0 1-.117-1.993L16 11h2V9a1 1 0 0 1 1-1zm-8.833-6a5 5 0 0 1 0 10H10a5 5 0 0 1 0-10h.167zm-.083 2h.083a3 3 0 0 0-.083 6H10a3 3 0 0 0 .083-6z" transform="translate(-820.052 268) translate(818 -270)"/>
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{ $t('Name') }}</th>
                    <th scope="col">{{ $t('Image') }}</th>
                    <th scope="col">{{ $t('Price rate') }}</th>
                    <th scope="col"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="industry in industries" :key="industry.id">
                    <td>{{ industry.id }}</td>
                    <td>{{ industry.name }}</td>
                    <td>
                        <div class="image-cell">
                            <img class="img-small" :src="industry.image" :alt="industry.name">
                        </div>
                    </td>
                    <td>{{ industry.price_rate }}</td>
                    <td class="text-end">
                        <router-link :to="{ name: 'EditIndustry', params: { id: industry.id } }" class="me-4">
                            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="15.006" height="15.013" viewBox="0 0 15.006 15.013">
                                <g>
                                    <g>
                                        <path d="M21.434 11.168l3.581 3.567-8.655 8.655-3.567-3.567zM11.7 24.027a.4.4 0 0 0 .117.344.4.4 0 0 0 .344.117l3.006-.344-3.124-3.134zm14.55-12.673l-1.431-1.431a1.507 1.507 0 0 0-2.136 0l-.272.272 3.577 3.567.272-.272a1.507 1.507 0 0 0 0-2.136z" transform="translate(-11.698 -9.479) translate(11.698 9.479) translate(-11.698 -9.479)"/>
                                    </g>
                                </g>
                            </svg>
                        </router-link>
                        <svg class="viewable" xmlns="http://www.w3.org/2000/svg" width="15" height="17.764" viewBox="0 0 15 17.764" @click="deleteIndustry(industry)">
                            <g>
                                <path d="M8.591 17.728a2.419 2.419 0 0 0 2.419 2.317h7.38a2.419 2.419 0 0 0 2.419-2.317l.475-10.939H8.116zm7.9-8.6a.605.605 0 0 1 1.21.039l-.279 7.933a.6.6 0 0 1-.6.584.6.6 0 0 1-.6-.626zm-4.207-.581a.6.6 0 0 1 .626.587l.281 7.926a.6.6 0 0 1-.6.626.6.6 0 0 1-.6-.584l-.266-7.935a.6.6 0 0 1 .569-.62zM21.6 4.37h-4.036V2.885a.6.6 0 0 0-.6-.6h-4.523a.6.6 0 0 0-.6.6V4.37H7.8a.6.6 0 0 0 0 1.21h13.8a.6.6 0 1 0 0-1.21zm-8.549-.88h3.308v.88h-3.313z" transform="translate(-7.2 -2.28)"/>
                            </g>
                        </svg>
                    </td>
                </tr>
            </tbody>
        </table>

        <transition name="fade">
            <add-industry-component v-if="showAddComponent" @closeModal="showAddComponent=false" />
        </transition>
    </div>
</template>

<script>

import {industryService} from "@/services/settings/industryService";
import AddIndustryComponent from "@/components/settings/AddIndustryComponent";
import HeaderSearch from '@/mixins/HeaderSearch'

export default {
    name: "Industries",
    mixins: [ HeaderSearch ],
    data() {
        return {
            industries: [],

            search: '',
            showAddComponent: false,
        }
    },
    created() {
        this.getIndustries()
    },
    methods: {
        searchFromHeader(keyword) {
            this.search = keyword;
            this.getIndustries();
        },
        getIndustries() {
            industryService.getIndustries({q: this.search}).then(response => this.industries = response.data.data)
        },
        deleteIndustry(industry) {
            let areYouSure = confirm('Are you sure?')

            if (areYouSure) {
                industryService.deleteIndustry(industry.id).then(() => {
                    const index = this.industries.indexOf(industry)
                    if (index >= 0) this.industries.splice(index, 1)
                })
            }
        }
    },
    components: {
        AddIndustryComponent
    }
}
</script>

<style scoped>

</style>