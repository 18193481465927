import axios from "@/axios";

class IndustryService {
    getIndustries(uriParams) {
        const reducer = (accumulator, currentValue) =>
            accumulator += currentValue[1] !== ''
                ? currentValue.join('=') + '&'
                : '';

        let url = '/settings/industries' + Object.entries(uriParams).reduce(reducer, '?').slice(0, -1)

        return axios.get(url);
    }
    addIndustry(formData) {
        return axios.post('/settings/industries', formData)
    }
    getIndustry(industryId) {
        return axios.get(`/settings/industries/${industryId}`)
    }
    updateIndustry(industryId, formData) {
        return axios.post(`/settings/industries/${industryId}`, formData)
    }
    deleteIndustry(industryId) {
        return axios.delete(`/settings/industries/${industryId}`)
    }
}

export const industryService = new IndustryService();