<template>
    <Modal>
        <template v-slot:modalContent>
            <svg class="close-button" @click="$emit('closeModal')" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
            </svg>
            <div class="modal-body">
                <div class="modal-header">
                    <h3>{{ $t('Add Industry') }}</h3>
                </div>

                <form @submit.prevent="addIndustry" class="invite-form">

                    <div class="form-group">
                        <input v-model="name" type="text" :class="['form-control', {'has-error': errors['name']}]" :placeholder="$t('Name')">

                        <FormErrors v-if="errors[`name`]" :errors="errors[`name`]" />
                    </div>

                    <div class="mb-3">
                        <label for="formFile" class="form-label">Image</label>
                        <input class="form-control" type="file" id="formFile"
                                accept="image/png,image/jpg,image/jpeg" @change="file = $event.target.files[0]">
                        
                        <FormErrors v-if="errors[`image`]" :errors="errors[`image`]" />
                    </div>

                    <button class="btn btn-primary" :disabled="disabled">{{ $t('Add') }}</button>
                </form>

            </div>
        </template>
    </Modal>
</template>

<script>

import {industryService} from "@/services/settings/industryService";
import Modal from '@/components/widgets/Modal';
import FormErrors from '@/components/widgets/FormErrors';

export default {
    name: "AddIndustryComponent",
    components: {
        Modal,
        FormErrors
    },
    data() {
        return {
            name: '',
            file: null,
        }
    },
    methods: {
        addIndustry() {
            this.disabled = true

            const formData = new FormData();

            if (this.file) {
                formData.append('image', this.file)
            }

            formData.append('name', this.name)

            industryService.addIndustry(formData)
                .then(() => {
                    this.$emit('closeModal')
                })
                .catch(error => {
                    this.errors = error.response.data.errors || []
                })
                .finally(() => this.disabled = false)
        }
    }
}
</script>

<style scoped>

</style>